

































































































































































































































































































































































































































































































import {Mixins, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import {InputText, InputCheckbox} from '@simpli/vue-input'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'
import {EnvHelper} from '@/helpers/EnvHelper'
import GasCalculatorFooter from '@/components/gasCalculator/GasCalculatorFooter.vue'
import {PropType} from 'vue'
import {GasCalculatorTargetCollection} from '@/model/collection/GasCalculatorTargetCollection'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {$} from '@/facade'

@Component({
  components: {
    GasCalculatorFooter,
    InputText,
    InputCheckbox,
    VueMultiselect,
  },
})
export default class GasCalculatorCard extends Mixins(MixinScreenSize) {
  @Prop({type: GasCalculatorTargetCollection, required: true})
  gasCalculatorTargetCollection!: GasCalculatorTargetCollection
  @Prop({
    type: ((null as unknown) as Object) as PropType<GasCalculatorTarget | null>,
    required: true,
  })
  selectedCandidate!: GasCalculatorTarget
  @Prop({type: String, required: true}) neoOwned!: string
  @Prop({type: Boolean, required: true}) useNeoBurger!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isSameCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isNoCandidate!: boolean
  @Prop({
    type: ((null as unknown) as Object) as PropType<GasCalculatorTarget | null>,
    required: true,
  })
  currentCandidate!: GasCalculatorTarget
  @Prop({type: Boolean, required: false, default: false})
  hasInsufficientNeo!: boolean
  @Prop({type: Boolean, required: true}) useNeoCompounder!: boolean
  @Prop({type: Boolean, required: false, default: true}) showTags!: boolean
  @Prop({type: String, required: false, default: 'text-2xl md:text-3xl'})
  titleClass!: string
  @Prop({
    type: String,
    required: false,
    default: () =>
      $.translate('components.gasCalculator.gasCalculatorCard.title'),
  })
  title!: string

  selectedCandidateInputValue = this.selectedCandidate ?? null
  isSelectedVotedCandidate =
    this.validateVotedCandidate(this.selectedCandidate) ?? false
  neoOwnedInputValue = this.neoOwned ?? '0'
  useNeoBurgerInputValue = this.useNeoBurger ?? false
  useNeoCompounderInputValue = this.useNeoCompounder ?? false

  get isSelectDisabled() {
    return this.useNeoBurger || this.useNeoCompounder
  }

  handleChangeNeoOwnedInputValue(event: Event) {
    const value = (event.target as HTMLInputElement).value.replace(
      /[^0-9]/g,
      ''
    )

    const parsedValue = parseInt(value)

    let newValue = value

    if (
      value.length - EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY.length > 1 &&
      parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)
    ) {
      newValue = EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY
    } else if (parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)) {
      newValue = value.slice(0, -1)
    }

    this.neoOwnedInputValue = newValue
    this.$emit('onChangeNeoOwned', newValue)
  }

  handleChangeUseNeoCompounderInputValue(value: boolean) {
    this.$emit('onChangeUseNeoCompounder', value)
    this.$emit('onChangeUseNeoBurger', false)
  }

  handleChangeUseNeoBurgerInputValue(value: boolean) {
    this.$emit('onChangeUseNeoBurger', value)
    this.$emit('onChangeUseNeoCompounder', false)
  }

  handleChangeSelectedCandidateInputValue(node: GasCalculatorTarget) {
    this.$emit('onChangeSelectedCandidate', node)
  }

  handleClickChangeVote() {
    this.$emit('onClickChangeVote')
  }

  validateVotedCandidate(node: GasCalculatorTarget) {
    return this.currentCandidate.publicKey === node.publicKey
  }

  showSquareSelected(node: GasCalculatorTarget) {
    return (
      this.validateVotedCandidate(node) &&
      this.$walletAdapter.n3ConnectedWalletPlatform
    )
  }

  @Watch('selectedCandidate')
  handleChangeSelectedCandidateProp() {
    this.selectedCandidateInputValue = this.selectedCandidate
    this.isSelectedVotedCandidate = this.validateVotedCandidate(
      this.selectedCandidate
    )
  }

  @Watch('currentCandidate')
  handleChangeCurrentCandidateProp() {
    this.isSelectedVotedCandidate = this.validateVotedCandidate(
      this.selectedCandidate
    )
  }

  @Watch('useNeoBurger')
  handleChangeUseNeoBurgerProp() {
    this.useNeoBurgerInputValue = this.useNeoBurger
  }

  @Watch('useNeoCompounder')
  handleChangeUseNeoCompounderProp() {
    this.useNeoCompounderInputValue = this.useNeoCompounder
  }

  @Watch('neoOwned')
  handleChangeNeoOwnedProp() {
    this.neoOwnedInputValue = this.neoOwned
  }
}
